import './multiStepForm.css'
import { useState, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { AuthContext } from "../../context/AuthContext"
import useMultiStepForm from '../../hooks/useMultiStepForm'
import DomainForm from '../domainForm/DomainForm'
import PasswordForm from '../passwordForm/PasswordForm'
import FormMessage from '../formMessage/FormMessage.jsx'

import { cgsauthAPIRequestUrl } from '../../utils/authRequest.js'
import { extractErrorMessage } from '../../utils/error.js'
import { permissionsAdapter, passwordRegex, deriveRedirectURL } from '../../utils/helper.js'
import { ACTION_TYPES, superappUIUrl } from "../../utils/definitions.js"

const MultiStepForm = ({source}) => {
    const [inputDomainId, setInputDomainId] = useState('')
    const [domainErrorStatus, setDomainErrorStatus] = useState(false)
    const [domainErrorMessage, setDomainErrorMessage] = useState('')
    const [domainAuthResponse, setDomainAuthResponse] = useState('')
    const [formActionText, setFormActionText] = useState('')
    const [formAction, setFormAction] = useState(0)
    const [isButtonLoading, setIsButtonLoading] = useState(false)
    const [credentials, setCredentials] = useState({
        domainId: undefined,
        password: '',
        confirmPassword: ''
    })
    const [isRegisterFormValidated, setIsRegisterFormValidated] = useState(false)
    const [isDomainValidated, setIsDomainValidated] = useState(false)
    
    const clearError = () => {
        setDomainErrorMessage('')
        setDomainErrorStatus(false)
    }

    const setError = (errorMsg, errorStatus) => {
        setDomainErrorMessage(errorMsg)
        setDomainErrorStatus(errorStatus)
    }

    const validateRegisterForm = (input, id) => {
        setCredentials(prev => ({...prev, [id]: input }))
        if(formActionText === 'Set Credentials') {
            if((credentials.employeeId !== '' 
            && credentials.employeeId?.length > 6
            && credentials.password !== ''
            && credentials.confirmPassword !== '') 
            && passwordRegex.test(credentials.password)
            &&
            (
                credentials.password === credentials.confirmPassword
            )
            ) {
                setIsRegisterFormValidated(true)
            }
            else {
                setIsRegisterFormValidated(false)
            }
        }
        else if (formActionText === 'Login Associate') {
            if( credentials.password !== '') setIsRegisterFormValidated(true)
            else setIsRegisterFormValidated(false)
        }
    }
    const validateDomainId = (input) => {
        setInputDomainId(input)
        if (inputDomainId !== '') setIsDomainValidated(true)
        else setIsDomainValidated(false)
    }

    const handleNext = async e => {
        e.preventDefault();
        setDomainErrorStatus(false)
        setDomainErrorMessage('')
        setIsButtonLoading(true)
        //validate domain id
        if (currentStepIndex + 1 === 1) {
            try {
                let config = {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    withCredentials: 'same-origin'
                }
                let obj = {
                    domainId: inputDomainId
                }
                const res = await cgsauthAPIRequestUrl.post("/verify", obj, config)
                setDomainAuthResponse(res.data)
                setIsButtonLoading(false)
                next()
            } catch (error) {
                if (error.response?.data !== undefined) {
                    console.log(error.response?.data)
                    setDomainErrorStatus(true)
                    setDomainErrorMessage(extractErrorMessage(error.response?.data))
                    setIsButtonLoading(false)
                }
                    // dispatch({type:ACTION_TYPES.FAILURE, payload: error.response?.data})
                else {
                    setDomainErrorStatus(true)
                    setDomainErrorMessage(error?.message)
                    console.log(error)
                    setIsButtonLoading(false)
                }
                    // dispatch({type:ACTION_TYPES.FAILURE, payload: error})
            }
        }
        //set credentials or login associate
        if (currentStepIndex + 1 === 2) {
            if(formActionText === 'Set Credentials') {
                try {
                    let config = {
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        withCredentials: 'same-origin'
                    }
                    let objCreds = {
                        domainId: inputDomainId,
                        employeeId: credentials.employeeId,
                        password: credentials.password
                    }
                    const res = await cgsauthAPIRequestUrl.post("/setCreds", objCreds, config)
                    res.data.permissions = permissionsAdapter(res.data.permissions)
                    dispatch({ type:ACTION_TYPES.SUCCESS, payload: res.data })
                    setIsButtonLoading(false)
                    window.location.href=deriveRedirectURL(source)
                } catch (error) {
                    if (error.response?.data !== undefined) {
                        console.log(error.response?.data)
                        setDomainErrorStatus(true)
                        setIsButtonLoading(false)
                        setDomainErrorMessage(extractErrorMessage(error.response?.data))
                    }
                    else 
                        console.log(error)
                }
            }
            else if (formActionText === 'Login Associate') {
                dispatch({type:ACTION_TYPES.START});
                try {
                    let config = {
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        withCredentials: 'same-origin'
                    }
                    let objCreds = {
                        domainId: inputDomainId,
                        password: credentials.password
                    }
                    const res = await cgsauthAPIRequestUrl.post("/verifyCreds", objCreds, config)
                    res.data.permissions = permissionsAdapter(res.data.permissions)
                    dispatch({ type:ACTION_TYPES.SUCCESS, payload: res.data })
                    setIsButtonLoading(false)
                    //window.location.href=deriveRedirectURL(source)
			window.location.href='https://checkin.teamcgs.io'
                } catch (error) {
                    if (error.response?.data !== undefined) {
                        console.log(error.response?.data)
                        dispatch({type:ACTION_TYPES.FAILURE, payload: error.response?.data})
                        setDomainErrorStatus(true)
                        setIsButtonLoading(false)
                        setDomainErrorMessage(extractErrorMessage(error.response?.data))
                    }
                    else 
                        console.log(error)
                }
            }
        }
    }

    const backAction = () => {
        clearError()
        setInputDomainId('')
        setDomainAuthResponse('')
        back()
    }

    const { steps, currentStepIndex, step, isFirstStep, isLastStep, back, next } = useMultiStepForm([
        <DomainForm 
            setInputDomainId={setInputDomainId} 
            handleNext={handleNext} 
            clearError={clearError} 
            title='Sign In' 
            validateDomainId={validateDomainId}  
        />, 
        <PasswordForm 
            domainAuthResponse={domainAuthResponse} 
            validateRegisterForm={validateRegisterForm} 
            setFormActionText={setFormActionText} 
            formAction={formAction} 
            setCredentials={setCredentials} 
            clearError={clearError} 
            setError={setError} 
            handleNext={handleNext}
        />
    ])
    const { loading, error, dispatch } = useContext(AuthContext);
    const navigate = useNavigate();
  return (
    <div className='multiStepContainer'>
            <form>
                <div className="stepCounter">{currentStepIndex + 1} / {steps.length}</div>
                {step}
                {domainErrorStatus && <div className='domainErrorMsg'>
                    {   Array.isArray(domainErrorMessage) ? 
                            domainErrorMessage?.map( (error, i) => (
                                <p className='passwordValidationError'>{error}</p>
                            )) 
                            : 
                            domainErrorMessage.includes('Associate') ?
                                <FormMessage message={domainErrorMessage} type='login'/>
                                :
                                <>
                                    <FormMessage message={domainErrorMessage} type='login'/>
                                    <a className='forgotPwLink' href='/reset/request'>Forgot Password?</a>
                                </>
                    }
                </div>}
                <div className="multiStepButtons">
                    {!isFirstStep && <button type='button' onClick={backAction}>Back</button>}
                    {
                        isLastStep ? 
                            <button type='button' onClick={handleNext} disabled={(!isRegisterFormValidated && !isButtonLoading) || (isRegisterFormValidated && isButtonLoading)}>{formActionText}</button>
                        :
                            <button type='button' onClick={handleNext} disabled={(!isDomainValidated && !isButtonLoading) || (isDomainValidated && isButtonLoading) }>Next</button>
                    }
                </div>
            </form>
        </div>
  )
}

export default MultiStepForm
